import "../scss/main.scss";

async function writeClipboardText() {
  try {
    const text = document.getElementById("short-url").getAttribute("href");
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(error.message);
  }
}

const ready = (callback) => {
  if (document.readyState != "loading") callback();
  else document.addEventListener("DOMContentLoaded", callback);
};

const onClickCopy = () => {
  const copyBtn = document.getElementById("copy");
  if (!copyBtn) {
    return;
  }
  copyBtn.addEventListener("click", () => writeClipboardText());
};

const focusInput = () => {
  if (document.getElementsByName("long_url").length === 1) {
    document.getElementsByName("long_url").item(0).focus();
  }
};

const onShare = () => {
  if (!navigator.share) {
    return;
  }

  const btn = document.querySelector("#share");

  console.log("btn.dataset.shortUrl", btn.dataset.shortUrl);

  const shareData = {
    title: "Urlsh",
    url: btn.dataset.shortUrl,
  };

  // Share must be triggered by "user activation"
  btn.addEventListener("click", async () => {
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(`Error: ${err}`);
    }
  });
};

ready(() => {
  onClickCopy();
  focusInput();
  onShare();
});
